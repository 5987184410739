.project-card {
    background-color: white;
    border: 2px solid #edf2f9;
    box-shadow: rgba($color: #12263f, $alpha: 0.03) 0 12px 24px 0;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease 0s;

    .description {
        height: 78px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .thumbnail {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: top;

        &.plasma {
            object-position: center;
        }

        &.mybikecounter {
            object-position: 72%;
        }

        &.bees {
            object-position: left;
        }

        &.ereception {
            object-position: 50% 34%;
        }
    }

    .title {
        border-top: 2px solid #edf2f9;
        border-radius: 0px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        font-weight: bold;
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: rgba($color: #12263f, $alpha: 0.06) 0 24px 48px 0;
    }
}

.primary-button {
    height: 38px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #5684f8;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    outline: none !important;
    border-radius: 8px;
    border-width: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px, rgba(0, 0, 0, 0.06) 0 1px 2px;
    cursor: pointer;
    transition: 0.3s background;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    text-decoration: none;

    &:hover {
        background-color: #3d67f6;
    }

    &:active {
        background-color: #3153c4;
    }

    &:disabled {
        opacity: 0.65;
        cursor: default;
    }
}

.circle-button {
    width: 32px;
    height: 32px;
    background-color: #ecf0f6;
    outline: none;
    border-radius: 999px;
    border-width: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px, rgba(0, 0, 0, 0.06) 0 1px 2px;
    color: #2f363d;
    cursor: pointer;
    transition: 0.3s background;

    img {
        padding: 11px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
    }

    &:hover {
        background-color: #e3e9f3;
    }

    &:active {
        background-color: #d4d8e2;
    }
}

.vr {
    width: 1px;
    margin: 0 1rem;
    color: inherit;
    background-color: rgb(33, 37, 41);
    border: 0;
    opacity: 0.25;
}

.container-xxl {
    transition: all 0.2s;
}

.modal-open .container-xxl {
    filter: blur(5px);
}

.modal-title {
    font-weight: bold;
}

.profile-pic {
    border-radius: 50%;
    border: 4px solid white;
    outline: 1px solid #edf2f9;
}

.ui-button {
    height: 32px;
    background-color: #f3f5f9;
    text-align: center;
    color: #2f363d;
    font-size: 11px;
    font-weight: 400;
    line-height: 32px;
    outline: none;
    border-radius: 8px;
    border-width: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px, rgba(0, 0, 0, 0.06) 0 1px 2px;
    cursor: pointer;
    transition: 0.3s background;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    text-decoration: none;

    padding-block: 1px;
    padding-inline: 6px;
    padding: 6px;
}

.main-headline {
    width: 100%;
    font-weight: bold;
}

.main-container {
    margin-top: 60px;
}

@media (max-width: 991px) {
    .profile-image-container {
        padding-right: 2rem;
    }

    .profile-pic {
        width: 140px;
        height: 140px;
    }

    .arrow-down-outline-button {
        display: none;
    }
}

.arrow-down-outline-button {
    vertical-align: -2px;
}

.social-media-text {
    padding-left: 4px;
    margin-bottom: 0;
}

.social-media-icon {
    ion-icon {
        font-size: 24px;
    }

    // font-size: 24px;

    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
    color: #2f363d;

    &:hover {
        color: #3d67f6;
    }
}

.footer-block {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #edf2f9;
}

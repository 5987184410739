.img-smart-cone-1 {
    height: 300px;
    object-fit: cover;
    object-position: top;
}

.img-smart-cone-2 {
    height: 285px;
    object-fit: cover;
    object-position: top;
}

.img-nt-plasma-1 {
    height: 300px;
    object-fit: cover;
    object-position: center;
}

.img-nt-plasma-2 {
    height: 300px;
    object-fit: cover;
    object-position: center;
}

.img-solar-counter-1 {
    height: 300px;
    object-fit: cover;
    object-position: 12% 50%;
}

.img-solar-counter-2 {
    height: 300px;
    object-fit: cover;
    object-position: center;
}

.img-solar-counter-photo {
    width: 567px;
    object-fit: cover;
    object-position: center;
}

.img-bees-project-1 {
    height: 300px;
    object-fit: cover;
    object-position: 0% 50%;
}

.img-bees-project-2 {
    height: 300px;
    object-fit: cover;
    object-position: 0% 0%;
}

.img-ereception-1 {
    height: 500px;
    object-fit: cover;
    object-position: 0% 35%;
}

.img-dag-sword-1 {
    object-fit: cover;
    object-position: center;
}

.dag-sword-title {
    max-width: 55%;
}
